<template>
  <div class="ChatBackgrounds__main-container">
    <span class="ChatBackgrounds__title">
      {{ title }}
    </span>
    <img
      v-if="imageUrl && !isActiveSpinner"
      :src="imageUrl"
      :key="imageUrl"
      class="ChatBackgrounds__image"
      alt="background"
    />
    <img
      v-if="isActiveSpinner"
      src="@/assets/svg/crown-loading-blue.svg"
      class="ChatBackgrounds__spinner"
    />
    <img
      src="@/assets/svg/arrow-left.svg"
      class="ChatBackgrounds__arrow"
      @click="$emit('goToList')"
    />

    <form class="ChatBackgrounds__form" @submit.prevent="onSubmit">
      <input
        ref="file"
        type="file"
        class="ChatBackgrounds__form-input-file"
        accept="image/*"
        @change="onFileChange"
      />
      <button
        v-if="!isActiveSpinner"
        type="button"
        name="button"
        value="Upload"
        class="ChatBackgrounds__form-upload"
        @click="$refs.file.click()"
      >
        Upload Background
      </button>
      <select v-if="imageUrl" v-model="selectedType" class="input">
        <option>MOBILE</option>
        <option>WEB</option>
      </select>
      <button
        v-if="imageUrl"
        type="submit"
        class="ChatBackgrounds__form-submit"
      >
        {{ buttonText }}
      </button>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'Form',

  props: {
    activeBackgroundData: {
      type: Object,
      required: false,
      default: () => null,
    },
  },

  data: () => ({
    imageUrl: '',
    isActiveSpinner: false,
    selectedType: 'MOBILE',
  }),

  computed: {
    title() {
      return this.activeBackgroundData
        ? 'Edit Background'
        : 'Upload New Background';
    },
    buttonText() {
      return this.activeBackgroundData ? 'Update' : 'Add to list';
    },
  },

  created() {
    if (this.activeBackgroundData) {
      this.imageUrl = this.activeBackgroundData.imageUrl;
      this.selectedType = this.activeBackgroundData.type;
    }
  },

  methods: {
    ...mapActions([
      'prepareAttachmentData',
      'addNewBackground',
      'updateBackground',
    ]),
    onSubmit() {
      if (this.activeBackgroundData) {
        this.updateBackground({
          imageUrl: this.imageUrl,
          type: this.selectedType,
          id: this.activeBackgroundData.id,
        }).finally(() => {
          this.$emit('goToList');
        });
      } else {
        this.addNewBackground({
          imageUrl: this.imageUrl,
          type: this.selectedType,
        }).finally(() => {
          this.$emit('goToList');
        });
      }
    },
    onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return false;
      this.sendImage(files[0]);
    },
    sendImage(file) {
      const img = new Image();
      img.src = window.URL.createObjectURL(file);
      const fileName = file.name
        ? file.name.replace(/[^A-Z0-9]+/gi, '_').replace(/(.*)_/, '$1.')
        : 'file';
      img.onload = () => {
        this.isActiveSpinner = true;
        this.prepareAttachmentData({ file, fileName }).then((resp) => {
          this.isActiveSpinner = false;
          this.imageUrl = resp;
        });
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheets/components/chat-backgrounds/Form.scss';
</style>
